<template>
  <div class="outer-wrap">
    <el-scrollbar wrap-class="scrollbar-wrapper" style="height:100%">
      <div class="protocol-wrap">
        <div class="protocol-title">京东物流开放平台隐私政策</div>
        <div class="MsoNormal" style="margin-left: 112px;">
          <div class="date">版本更新日期：2020年8月20日</div>
          <div class="date">版本生效日期：2020年8月20日</div>
        </div>
        <div style="font-weight: bold;font-size:14pt;text-align:center;margin:20.8px 0">引言</div>
        <div
          class="content"
          style="width:1000px;margin-left:100px;margin-top:-68px;font-family:Microsoft Yahei"
        >
          <div>
            京东物流及其关联公司（“关联公司”是指不时控制京东物流或其实体、或被京东物流或其实体所控制、或与京东物流或其实体被共同控制之下的任何实体，其中“控制”一词意指直接或间接具备或控制50%以上投票权）（以下简称“
            <u
              style="font-weight:bold"
            >京东物流</u>”或“
            <u style="font-weight:bold">我们</u>”）非常重视用户的（以下简称“
            <u style="font-weight:bold">您</u>”）个人信息和隐私保护。我们希望通过《京东物流隐私政策》（“本隐私政策”）向您说明在您使用我们的产品与/或服务时 如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
          </div>
          <div style="margin-top:14px">
            <u style="font-weight:bold">本隐私政策将帮助您了解以下内容：</u>
          </div>
          <div>（1）我们如何收集和使用您的个人信息；</div>
          <div>（2） 信息的使用目的、方式和范围；</div>
          <div>（3） 我们如何使用 Cookie 和同类技术；</div>
          <div>（4） 我们如何共享、转让、公开披露您的个人信息；</div>
          <div>（5） 您如何管理您的个人信息；</div>
          <div>（6） 我们如何保护和保存您的个人信息；</div>
          <div>（7） 未成年人的个人信息保护；</div>
          <div>（8） 通知与修订；</div>
          <div>（9）如何联系我们。</div>
          <div style="margin:14px 0">
            <u
              style="font-weight:bold;"
            >本隐私政策与您所使用的京东物流服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品与/或服务，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。</u>
          </div>
          <div style="margin-bottom:16px;">如对本隐私政策或相关事宜有任何问题，您可随时拨打客服电话：950616与我们联系。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:16px">一、我们如何收集和使用您的个人信息</div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（一）您须授权我们收集和使用您个人信息的情形</div>
          <div>
            个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
            <u
              style="font-weight:bold"
            >本隐私政策</u>中涉及的个人信息包括：基本信息（包括个人姓名、住址、个人电话号码、电子邮箱）；个人身份信息（包括身份证等）；网络身份标识信息（包括系统账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人上网记录（包括网站浏览记录、软件使用记录、点击记录）；个人常用设备信息（包括硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码（如IMEI/androidID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息））；个人位置信息（包括行程信息、精准定位信息、住宿信息、经纬度等）；
          </div>
          <div style="margin:14px 0;">
            <u style="font-weight:bold">个人敏感信息</u>是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，
            <u
              style="font-weight:bold"
            >本隐私政策中涉及的个人敏感信息包括</u>：个人身份信息（包括身份证、军官证、护照、驾驶证、户口本）；网络身份识别信息（包括账号、邮箱地址及与前述有关的密码口令的组合）；其他信息（包括通讯录、行程信息、网页浏览记录、住宿信息、精准定位信息）。
          </div>
          <div style="margin:14px 0;">京东物流可能会在下列活动中，收集您的个人信息：（1）使用京东物流开放平台网站和应用，（2）问卷调查。</div>
          <div style="margin-top:14px;">京东物流可能获得的个人信息类型包括：</div>
          <div>• 用于获取京东物流产品和服务的用户名（如商家自助平台、在线签约服务等）、密码和其他证书；</div>
          <div>• 要求京东物流向其发送信息之其他人士的姓名、电邮地址和电话号码；</div>
          <div>• 回答问卷调查时提供的信息；</div>
          <div>• 在京东物流的官方社交媒体页面或互联网其他地方（例如其他公共区域）发布的社交媒体用户名、内容和其他数据，通过京东物流相关的社交媒体应用、工具和插件（包括第三方登录服务）获取的信息；</div>
          <div>• 您为获取京东物流产品或服务而提供给京东物流的其他个人信息；</div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（二）您个人信息使用的规则</div>
          <div>1、我们会根据本隐私政策的约定并为实现我们的服务功能对所收集的个人信息进行使用。</div>
          <div>
            2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。
            <u
              style="font-weight:bold"
            >请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</u>
          </div>
          <div>
            <u
              style="font-weight:bold"
            >3、请您注意，您在使用我们的服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息，具体请详见《京东账户注销须知》。</u>
          </div>
          <div>4、我们会对我们的服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</div>
          <div>5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</div>
          <div>6、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:16px">二、信息的使用目的、方式和范围</div>
          <div>京东物流可能会将收集的个人信息用于：</div>
          <div>• 就您的要求、问题和意见，向您提供客户支持、向您做出回应并与您沟通；</div>
          <div>• 建立和管理您的京东物流账户（如线上签约平台、京东物流开放平台注册账号等）；</div>
          <div>• 应您的要求，向您的联系人发送信息；</div>
          <div>• 处理就京东物流的服务所收到的申诉；</div>
          <div>• 经营、评估和改善京东物流的业务（包括开发新产品和服务，管理京东物流的通信，判断京东物流的销售、营销和广告效果，分析和强化京东物流的产品、服务、网站和应用，和开展会计、审计、记账、对账）；</div>
          <div>• 进行数据分析（包括市场和消费者调查、趋势分析、财务分析）；</div>
          <div>• 避免、识别和防止欺诈以及其他犯罪活动、申诉或其他法律责任；</div>
          <div>• 遵从京东物流所应适用的法律规定和京东物流的政策。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:16px">三、 我们如何使用 Cookies 和同类技术</div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（一）Cookies的使用</div>
          <div>1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies 是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储等数据进而为您提供服务的偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账户或数据安全。</div>
          <div>2、我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问京东物流开放平台网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（二）网络Beacon和同类技术的使用</div>
          <div>除 Cookies 外，我们还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些电子图像（称为"单像素" GIF 文件或 "网络 beacon"）。我们使用网络beacon的方式有：</div>
          <div>1. 通过在京东物流网站上使用网络beacon，计算用户访问数量，并通过访问 cookies辨认注册的京东物流用户。</div>
          <div>2. 通过得到的cookies信息，为您提供个性化服务。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:16px">四、 我们如何共享、转让、公开披露您的个人信息</div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（一）共享</div>
          <div style="font-weight:bold;">1、我们不会与京东物流以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</div>
          <div>（1）事先获得您明确的同意或授权；</div>
          <div>（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</div>
          <div>（3）在法律法规允许的范围内，为维护京东物流、京东物流的关联方或合作伙伴、您或其他京东物流用户或社会公众利益、财产或安全免遭损害而有必要提供；</div>
          <div>（4） 只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；</div>
          <div>（5）应您需求为您处理您与他人的纠纷或争议；</div>
          <div>（6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</div>
          <div>（7）基于学术研究而使用；</div>
          <div>（8）基于符合法律法规的社会公共利益而使用。</div>
          <div
            style="font-weight:bold;"
          >2、我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。</div>
          <div
            style="font-weight:bold;"
          >3、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。为了保障数据在第三方安全可控，我们在云端提供安全可靠的数据使用和存储环境，确保用户数据的安全性。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。</div>
          <div
            style="font-weight:bold;"
          >4、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护京东物流、您或其他京东物流客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（二）转让</div>
          <div>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</div>
          <div>1、事先获得您明确的同意或授权；</div>
          <div>2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</div>
          <div>3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</div>
          <div
            style="font-weight:bold;"
          >4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（三）公开披露</div>
          <div>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</div>
          <div>1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</div>
          <div>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:16px">五、您如何管理您的个人信息</div>
          <div>京东非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（一）访问和更正您的个人信息</div>
          <div>您可以使用您在京东物流官网的账号和密码登陆您所注册的京东物流相关网站，查询您注册时向京东物流提供的个人信息。您还可与我们联系，以查询京东物流所保存的您的个人信息。为保护您的隐私安全，京东物流将在授权您查询该等信息前验证您的身份。</div>
          <div>如果您认为京东物流所保存的您的该等个人信息不准确或不完整，您可以直接在您所注册的相关网站修改该等信息，或者与我们联系，要求京东物流对其进行更正或修改。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（二）删除您的个人信息</div>
          <div>
            <u style="font-weight:bold">在以下情形中，您可以向京东物流提出删除您个人信息的请求：</u>
          </div>
          <div>
            <u style="font-weight:bold">（1）如果我们处理您个人信息的行为违反了法律规定；</u>
          </div>
          <div>
            <u style="font-weight:bold">（2）如果我们收集、处理您个人信息的行为，未取得您的同意；</u>
          </div>
          <div>
            <u style="font-weight:bold">（3）如果我们处理您个人信息的行为违反了与您的约定；</u>
          </div>
          <div>
            <u style="font-weight:bold">（4）如果我们终止服务及运营。</u>
          </div>
          <div>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。若我们响应您的删除请求，我们不能保证立即从备份系统中删除相应的信息，但会在备份系统更新时删除这些信息。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（三）改变您授权同意的范围或撤回您的授权</div>
          <div>
            <u
              style="font-weight:bold"
            >您可以通过删除信息、关闭设备功能、在京东物流网站中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。</u>
          </div>
          <div>
            <u
              style="font-weight:bold"
            >请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</u>
          </div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（四）注销账户</div>
          <div>
            因为您在京东商城网站的账户与京东物流官网是相同的，如果您希望注销京东物流官网账户的，您可以通过在京东商城网站（https://www.jd.com/）中直接申请注销账户。关于您注销账户的方式以及您应满足的条件，请详见
            <a
              href="https://about.jd.com/privacy/#b-f8"
              target="_blank"
              style="color:blue"
            >《京东账户注销须知》</a>。您注销账户后，我们将停止为您提供产品与/或服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息。
          </div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（五）响应您的请求</div>
          <div
            style="font-weight:bold"
          >如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息，或您认为京东存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以发送电子邮件至privacy@jd.com或通过隐私政策中的其他方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</div>
          <div style="font-weight:bold">在以下情形中，按照法律法规要求，我们将无法响应您的请求：</div>
          <div style="font-weight:bold">（1）与国家安全、国防安全有关的；</div>
          <div style="font-weight:bold">（2）与公共安全、公共卫生、重大公共利益有关的；</div>
          <div style="font-weight:bold">（3）与犯罪侦查、起诉和审判等有关的；</div>
          <div style="font-weight:bold">（4）有充分证据表明您存在主观恶意或滥用权利的；</div>
          <div style="font-weight:bold">（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:16px">六、我们如何保护和保存您的个人信息</div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（一）我们保护您个人信息的技术与措施</div>
          <div>我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</div>
          <div>1、数据安全技术措施</div>
          <div>我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。</div>
          <div>京东物流的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。</div>
          <div>京东物流采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。</div>
          <div>在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性。</div>
          <div>京东物流采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。</div>
          <div>京东物流采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。</div>
          <div>2、京东物流为保护个人信息采取的其他安全措施</div>
          <div>京东物流通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。</div>
          <div>京东物流通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</div>
          <div>京东物流建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。</div>
          <div>安全认证和服务。我们存储您个人数据的底层云技术取得了数据中心联盟颁发的“可信云”认证三级认证、通过了公安部安全等级保护三级认证，同时还获得了ISO27000认证。</div>
          <div>加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</div>
          <div>3、我们仅允许有必要知晓这些信息的京东物流及京东物流关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与京东物流的合作关系。</div>
          <div>4、我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</div>
          <div>5、我们还在用户数据保护上做了一些创造性工作</div>
          <div>在京东物流配送，采用了独特的“微笑面单”，避免用户敏感数据在配送环节的暴露，同时,大力强化对京东物流员工的数据安全培训和要求，提高物流员工保护用户隐私数据的意识。</div>
          <div>6、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。</div>
          <div>7、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</div>
          <div>8、安全事件处置</div>
          <div>在通过京东物流网站时，您不可避免披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。</div>
          <div>为应对个人信息泄露、损毁和丢失等可能出现的风险，京东物流制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。京东物流也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</div>
          <div>在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</div>
          <div>
            9、如果您对我们的个人信息保护有任何疑问，可通过本政策最下方约定的联系方式联系我们。
            <span style="font-weight:bold">
              如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策最下方
              <u>【如何联系我们】</u>约定的联系方式联络我们，以便我们采取相应措施。
            </span>
          </div>
          <div style="margin:14px 0;font-weight:bold;font-size:14px">（二）您个人信息的保存</div>
          <div>1、您的个人信息将全被存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。</div>
          <div>
            2、您的个人信息或账号，我们将遵循
            <span style="font-weight:bold">《京东用户注册协议》、《京东隐私政策》</span>。
          </div>
          <div>
            <u style="font-weight:bold">3、如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</u>
          </div>
          <div style="margin:14px 0;font-weight:bold;font-size:16px">七、 未成年人的个人信息保护</div>
          <div>1、京东非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的服务前，应事先取得您监护人的同意。京东根据国家相关法律法规的规定保护未成年人的个人信息。</div>
          <div>2、我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</div>
          <div>3、如果有事实证明未成年人并未取得监护人同意的情况下注册使用了我们的服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。</div>
          <div>4、对于可能涉及的不满14周岁的儿童个人信息，我们进一步采取以下措施予以保障：</div>
          <div>（1）对于收集到的儿童个人信息，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。我们会指定专人负责儿童个人信息保护事宜，并设立了儿童个人信息保护的专门邮箱childprivacy@jd.com。我们还会制定儿童个人信息保护的内部专门制度。</div>
          <div>（2）当您作为监护人为被监护的儿童选择使用京东相关服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用评价等功能分享服务相关信息时可能会主动向我们提供儿童个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。若儿童本人需要注册或使用我们的服务，您应正确引导并予以监护。</div>
          <div>（3）儿童或其监护人有权随时访问和更正儿童个人信息，还可以向我们提出更正和删除的请求。如您对儿童个人信息相关事宜有任何意见、建议或投诉、举报，请联系我们。我们会随时为您提供帮助。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:16px">八、通知和修订</div>
          <div>1、为给您提供更好的服务以及随着京东物流业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在京东物流官网发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问京东物流以便及时了解最新的隐私政策。</div>
          <div>2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。</div>
          <div>本隐私政策所指的重大变更包括但不限于：</div>
          <div>（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</div>
          <div>（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</div>
          <div>（3）个人信息共享、转让或公开披露的主要对象发生变化；</div>
          <div>（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</div>
          <div>（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</div>
          <div>（6）个人信息安全影响评估报告表明存在高风险时。</div>
          <div>3、我们还会将本隐私政策的旧版本存档，供您查阅。</div>
          <div style="margin:14px 0;font-weight:bold;font-size:16px">九、如何联系我们</div>
          <div>1、为了更好地保护您的个人信息，更便捷地与您进行个人信息保护相关的沟通，京东物流设立了专门的信息保护部门。如果就个人信息方面您对京东物流有任何请求或投诉，或者对本政策有任何疑问，您可通过以下方式与我们取得联系。一般情况下，对于您的咨询、请求或投诉，京东物流会在合理期限内向您作出答复和解释。</div>
          <div style="font-weight:bold;margin-top:10px">联系电话：950616</div>
          <div style="font-weight:bold;margin-top:10px">个人信息保护负责人邮箱privacy@jd.com</div>
          <div style="font-weight:bold;margin-top:10px">联系地址：北京亦庄经济技术开发区科创11街18号院</div>
          <div>2、一般情况下，我们将在15天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。注：本隐私政策版本更新日期为2020年8月20日，将于2020年8月20日正式生效。</div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  components: {},
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.outer-wrap {
  margin-top: 64px;
  ::v-deep .el-scrollbar__thumb {
    display: none;
  }
  .protocol-wrap {
    border-top: 0.1rem solid #eee;
    padding: 0.22rem;
    .protocol-title {
      padding-top: 0.4rem;
      padding-bottom: 0.2rem;
      width: 9.9rem;
      font-size: 0.32rem;
      color: #333;
      text-align: center;
      margin: 0 auto 0.35rem;
      min-height: 0.8rem;
      border-bottom: 0.01rem dashed #d0d0d0;
      .MsoNormal {
        margin-bottom: 0.0001pt;
        text-align: justify;
        font-size: 10.5pt;
        font-family: DengXian;
        .date {
          font-size: 10.5pt;
          font-family: Arial, Helvetica, sans-serif;
        }
      }
    }
  }
}
</style>